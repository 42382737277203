export const paragraphs = {
    welcomeText1: "Odysape is a gateway to a digital odyssey where adventure, exploration, and storytelling are intertwined. Each NFT is a unique piece of an epic unfolding. With its mysterious “monkey” symbol, Odysape pays homage to the NFT community, creating a rare and striking identity that shines in the ever-evolving realms of digital art and blockchain",
    welcomeText2: "Join the adventure",
    abousUs: "At Odysape, we believe in the transformative power of storytelling, creativity, and technology. Our mission is to redefine the NFT space by creating a universe where imagination and innovation converge. With a passionate team of visionaries, artists, and developers, we are committed to delivering an unparalleled experience that goes beyond traditional digital collectibles.\n" +
      "\n" +
      "Odysape is more than a project; it's a movement. By combining high-quality art, interactive storytelling, and a vibrant community, we aim to inspire adventurers, creators, and collectors to join us in shaping the future of NFTs. Each step of our journey reflects our dedication to pushing boundaries and exploring new possibilities.\n" +
      "\n" +
      "We invite you to become part of our story. Together, let's build a universe where creativity knows no limits.",
    ourCommunity: "Join our vibrant community of gaming and blockchain enthusiasts on Discord and Twitter! Together, we shape the future of our project through collaborative updates and collective decision-making. Connect, contribute, and be part of our journey.",
    information: {
        infos: [
            {
                id: 'info_rewards',
                title: "What is Odysape?",
                mini_title: '',
                text: "Odysape is a revolutionary NFT project that ingeniously fuses storytelling, adventure, and cutting-edge technology. Each NFT is a part of a vibrant and evolving narrative, offering users a captivating and immersive experience.",
            },{
                id: 'info_hungry',
                title: "How can I get involved in the early stages?",
                mini_title: 'You can join Phase 2:',
                text: "The Spark by joining the Initial NFT Offering (INO). Keep an eye out for our announcements and updates on how to join and become an early supporter of this unique project.",
            },{
                id: 'info_fight',
                title: "What happens after I purchase an NFT?",
                mini_title: '',
                text: "Your NFT opens the door to the dynamic and ever-evolving Odysape universe. Here, you can immerse yourself in challenges, uncover hidden puzzles, and engage with the living, breathing storyline.",
            },{
                id: 'info_competitions',
                title: "Will there be rewards for participating?",
                mini_title: 'Definitely! ',
                text: "Odysape offers staking mechanisms, which allow you to earn rewards by holding your NFTs for a certain period, dynamic rewards that change based on your participation, and opportunities to earn exclusive collectibles. These unique digital items are only available to those participating during Phases 4 and 5.",
            },{
                id: 'info_jungle',
                title: "How can the community influence the project?",
                mini_title: 'During Phase 5:',
                text: "The Quest, your actions and ideas will be woven into the lore, making every participant a significant co-creator in the Odysape journey.",
            },{
                id: 'info_earn',
                title: "What makes Odysape different from other NFT projects?",
                mini_title: '',
                text: "Odysape is more than an NFT project—it’s an ecosystem combining interactive storytelling, high-quality art, and gamified experiences. Each phase of the roadmap is designed to engage users and push the boundaries of digital ownership.",
            },
        ],
        images: [
            {
                id: 0,
                image: '/Assets/extra/extra_image_2.png',
                alt: 'extra_image_2',
                display: false,
            },{
                id: 1,
                image: '/Assets/extra/extra_image_6.webp',
                alt: 'extra_image_3',
                display: true,
            },{
                id: 2,
                image: '/Assets/extra/extra_image_6.webp',
                alt: 'extra_image_5',
                display: true,
            }
        ]
    }
}