export const roadmap = [
    {
        id: 'st-1',
        step: 1,
        title: 'Phase 1: he Awakening',
        description: `Every masterpiece begins with its creators.`,
        text: `Assemble a multidisciplinary team of visionaries, coders, and storytellers.

Forge alliances with industry pioneers and advisors to lay the foundation.

Create the blueprint for a revolutionary ecosystem where imagination meets innovation.

Outcome: The cornerstone of our universe is set, awaiting the spark of life.`,
        image: "/Assets/roadmap/1.png",
        link: "",
        active: true,
    },{
        id: 'st-2',
        step: 2,
        description: `Fueling the dream, igniting the possibilities.`,
        title: 'Phase 2: The Spark',
        text: `Launch a targeted INO to attract forward-thinking investors.

Introduce the first wave of supporters to the concept, blending exclusivity with intrigue.

Use raised capital to unlock resources and technology for the next phase.

Outcome: The community begins to coalesce, and the dream transforms into a shared mission.`,
        image: "/Assets/roadmap/2.png",
        link: "",
        active: true,
    },{
        id: 'st-3',
        step: 3,
        description: 'Crafting experiences from pure potential.',
        title: 'Phase 3: The Forge',
        text: `Build and refine the first playable prototype—a gateway to an enigmatic world.

Test the mechanics and narrative with a select group of beta adventurers.

Optimize the core elements to ensure an unparalleled user experience.

Outcome: A tantalizing glimpse into the future, setting the stage for boundless exploration.`,
        image: "/Assets/roadmap/3.png",
        link: "",
        active: true,
    },{
        id: 'st-4',
        step: 4,
        description: 'When vision becomes reality, the gates open.',
        title: 'Phase 4: The Unveiling',
        text: `Release the platform and activate the staking mechanism, allowing participants to earn and grow.

Publicly launch the NFTs, imbued with hidden puzzles and secrets awaiting discovery.

Host immersive experiences to reward early adopters and pioneers.

Outcome: The first steps into a universe where value, curiosity, and creativity converge.`,
        image: "/Assets/roadmap/4.png",
        link: "",
        active: true,
    },{
        id: 'st-5',
        title: 'Phase 5: The Quest',
        step: 5,
        description: `Mysteries await those daring enough to seek.`,
        text: `Roll out an interactive game designed to challenge minds and foster collaboration.

Introduce dynamic rewards, creating moments of triumph for the community.

Weave the community’s actions into the lore, making every player a co-creator.

Outcome: A living, breathing ecosystem fueled by collective imagination and discovery.`,
        image: "/Assets/roadmap/5.png",
        link: '',
        active: true,
    },{
        id: 'st-6',
        step: 6,
        description: `Minds connected, markets aligned, the journey begins anew.`,
        title: 'Phase 6: The Nexus',
        text: `Expand the ecosystem with advanced features, partnerships, and cross-platform integrations.

Anchor the project in a sustainable market through strategic collaborations.

Elevate the narrative into a global movement, redefining the boundaries of NFTs.

Outcome: The journey completes its first cycle, but the possibilities remain infinite.`,
        image: "/Assets/roadmap/6.png",
        link: '',
        active: true,
    },
]