import React from "react";
import { avatars } from "../../../Utils/Constants/avatars";
import { paragraphs } from "../../../Utils/Constants/paragraphs";
import SocialIndex from "../Social/SocialIndex";

export default function WelcomeIndex() {
    return (
        <div id="home-section" className="section-welcome">
            <div className="title">
                <h1>Odys Ape</h1>
                <h1>is not just an NFT project</h1>
            </div>
            <div className="icons">
                {
                    avatars.filter(i => i.display).sort((a, b) => a.order - b.order).map(i => {
                        return(
                            <div key={i.alt+Date.now()} className={`inner order${i.order}`}>
                                <img src={i.image} alt={i.alt} />
                            </div>
                        )
                    })
                }
            </div>
            <div className="text">
                <p>{paragraphs.welcomeText1}</p>
                <p>{paragraphs.welcomeText2}</p>
                <SocialIndex />
            </div>
        </div>
    );
  }
  