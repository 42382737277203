import { roadmap } from "../../../Utils/Constants/roadmap";

export default function RoadmapIndex() {
    const onScrollRoadMap = (e) => {
        const mris = document.getElementById('mris');
        document.getElementById('sii').style.width = `${(mris.scrollLeft*100)/(mris.scrollWidth - mris.clientWidth)}%`;
    }

    return (
        <div id='roadmap-section' className="section-roadmap">
            <p className="section-title">{'Roadmap'}</p>
            <div id="mris" className="roadmap-items" onScroll={onScrollRoadMap}>
                {
                    roadmap.filter(i => i.active).map((i, index) => {
                        return (
                            <div key={i.id} className="inner">
                                <div className="step-number">
                                    <p>{i.title}</p>
                                </div>
                                <p>{i.description}</p>
                                <img src={i.image} alt={i.id} />
                                <p>{i.text}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className="custom-scroll">
                <div id="sii" className="inner"/>
            </div>
        </div>
    );
  }
  